.App {
  text-align: center;
}

.App-logo {
  height: 120vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.input {
    color: white;
    font-size: 12px;
    font: Verdana;
}

.btn {
    background-color: #4CAF50;
    border: none;
    color: white;
    margin: 4px;
    padding: 3px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
}
